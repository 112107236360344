.footer {
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background-color: darkcyan;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.credits {
  text-decoration: none;
  color: whitesmoke;
}
