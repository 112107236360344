.form2 {
  height: auto;
  margin-bottom: 100px;
}

.input {
  width: 100%;
  padding: 5px;
  outline: none;
  margin: 10px;
}

.frmbtn {
  margin: 10px;
  width: 100%;
}

.submit {
  padding: 5px;
  float: right;
  cursor: pointer;
  background-color: crimson;
  color: whitesmoke;
  outline: none;
  border: 2px solid #eee;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.9rem;
}
