.error {
  width: 100%;
  height: 100%;
}

.errBdy {
  width: 100%;
  min-height: calc(100vh - 80px);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errBdy p {
  margin: 10px 0;
  font-size: 1.4rem;
}
