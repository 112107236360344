* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  background-color: #fefefe;
}

.foo {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.linkdiss {
  text-decoration: none;
  color: whitesmoke;
}

.box1 {
  width: 100vw;
  height: auto;
  margin: auto;
  display: flex;
}

.searchForms {
  width: 90vw;
  height: auto;
  margin: auto;
}

.centered {
  margin: 30px 0;
  text-align: center;
}

@media (min-width: 1281px) {
  /* CSS */
}

@media (min-width: 1025px) and (max-width: 1280px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
  .box1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .searchForms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

@media (min-width: 481px) and (max-width: 767px) {
  .box1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .searchForms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .box1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .searchForms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  /* CSS */
}
