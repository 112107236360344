.sidebar {
  min-width: 250px;
  min-height: (100vh - 140px);
  height: auto;
  background-color: crimson;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.activeSidebar {
  display: none;
}

.sidebarEle {
  width: 100%;
  height: 40px;
  display: flex;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  color: #264653;
  text-decoration: none;
  border: 2px solid wheat;
  border-radius: 5px;
  background-color: #e9c46a;
}

@media (min-width: 424px) and (max-width: 1024px) {
  .activeSidebar {
    display: block !important;
  }
  .sidebar {
    display: none;
  }
  .sidebar {
    position: absolute;
    z-index: 1000;
    min-height: calc(100vh - 140px);
    height: auto;
  }
}

@media (min-width: 300px) and (max-width: 426px) {
  .activeSidebar {
    display: block !important;
  }
  .sidebar {
    display: none;
  }
  .sidebar {
    position: absolute;
    z-index: 1000;
    min-height: calc(100vh - 140px);
    height: auto;
  }
}
