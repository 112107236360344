* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
}
html,
body {
  height: 100%;
  font-family: "Recursive", sans-serif;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m10 {
  margin: 10px;
}

.outerDiv {
  width: 80%;
  margin: auto;
}

.messageBoxSuccess {
  width: auto;
  min-height: 40px;
  height: auto;
  margin: 5px 0;
  padding: 10px;
  color: whitesmoke;
  border-radius: 10px;
  background-color: green;
}

.messageBoxFail {
  width: auto;
  min-height: 40px;
  height: auto;
  margin: 5px 0;
  padding: 10px;
  color: whitesmoke;
  border-radius: 10px;
  background-color: rgb(230, 7, 7);
}
