.BedsStats {
  margin: 10px;
  height: auto;
}
.ouuterBox {
  width: 80%;
  height: auto;
  margin: auto;
  border-radius: 10px;
  border: 2px solid #eee;
}
.innerBox1 {
  padding: 10px;
  display: flex;
  border-radius: 10px 10px 0px 0px;
  justify-content: space-around;
  background-color: #e63946;
}

.innerBox2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  font-size: 1.4rem;
  border-radius: 0px 0px 10px 10px;
  background-color: cornsilk;
}

.innerBox2 div p {
  margin: 10px;
}
