.card {
  min-width: 250px;
  min-height: 200px;
  max-width:300px;
  height: auto;
  margin:20px;
  padding: 10px;
  background-color: #48cae4;
  border: 2px solid #eee;
  border-radius: 10px;
  color: #000000;
}
.ttl {
  font-size: 2rem;
}
.bds,
.avbds {
    font-size: 1.3rem;
    margin:10px 0;
}
