.intro {
  width: 50%;
  height: 200px;
  margin: 10px;
  border: 2px solid #eee;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.introbtn {
  width: 100px;
  margin: 10px;
  border-radius: 5px;
}



@media (min-width: 1281px) {
  /* CSS */
}

@media (min-width: 1025px) and (max-width: 1280px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
  .intro {
    width: 80%;
    height: 250px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

@media (min-width: 100px) and (max-width: 767px) {
  .intro {
    width: 80%;
    height: 250px;
  }
}
